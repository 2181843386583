@import 'colors.less';
@import 'margin.less';
@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

.center-vh {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.end-vh {
  display: flex !important;
  justify-content: left !important;
  align-items: flex-end !important;
}

.center-left-vh {
  display: flex !important;
  justify-content: left !important; 
  align-items: center !important;
}

.center-right-vh {
  display: flex !important;
  justify-content: flex-end !important; 
  align-items: center !important;
}

.text-center {
  text-align: center;
}

p {
  margin-bottom: 0;
}

.ro-value-div {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.ant-form-item {
  margin-bottom: unset;
  .ant-form-item-label {
    padding-bottom: 0;
    label {
      color: var(--silver-chalice);
    }
  }
}

// .ant-form-item-explain, .ant-form-item-extra {
//   min-height: unset;
// }

// .ant-form-item-with-help {
//   margin-bottom: 24px;
// }

.ant-form-item-label > label::after {
  content: unset;
}
@primary-color: #14B2BF;@layout-header-background: #00263D;@slider-track-background-color: #6AC259;@slider-track-background-color-hover: #6AC259;@slider-handle-color: #6AC259;@slider-handle-color-hover: #6AC259;@slider-handle-color-tooltip-open: #6AC259;