:root {
    --orange-peel: #F99B00;
    --cognac:#92341B;
    --cream: #FFEFD1;
    --red: #EC0C11;
    --black: #231F20;
    --grey: #B5B5B5;
    --light-grey: #E8E8E8;
    --green: #01983B;
    --white: #FFFFFF;
    --java: #14B2BF;
    --boulder: #767676;
    --alto: #E0E0E0;
    --silver-chalice: #9E9E9E;
    --daintree: #00263D;
}